 /* line 14969 */
 .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option.select2-results__option--selected, .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[aria-selected=true]:not(.select2-results__option--highlighted) {
    color: var(--bs-body-bg);
    background-color: #99C9FF;
 }
 
 /* line 15034 */
 [data-bs-theme="light"] .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option.select2-results__option--selected, 
 [data-bs-theme="light"] .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[aria-selected=true]:not(.select2-results__option--highlighted) {
    color: var(--bs-body-color);
    background-color: #99C9FF;
 }

 /* line 15084 */
.select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
   color: var(--bs-body-color);
}

/* line 15176 */
.is-valid + .select2-container--bootstrap-5 .select2-selection, .was-validated select:valid + .select2-container--bootstrap-5 .select2-selection {
   border-color: var(--bs-form-valid-border-color);
}

/* line 15179 */
.is-valid + .select2-container--bootstrap-5.select2-container--focus .select2-selection, .is-valid + .select2-container--bootstrap-5.select2-container--open .select2-selection, .was-validated select:valid + .select2-container--bootstrap-5.select2-container--focus .select2-selection, .was-validated select:valid + .select2-container--bootstrap-5.select2-container--open .select2-selection {
   border-color: var(--bs-form-valid-border-color);
 }

/* line 15191 */
.is-invalid + .select2-container--bootstrap-5 .select2-selection, .was-validated select:invalid + .select2-container--bootstrap-5 .select2-selection {
   border-color:  var(--bs-form-invalid-border-color);
 }

/* line 15194 */
.is-invalid + .select2-container--bootstrap-5.select2-container--focus .select2-selection, .is-invalid + .select2-container--bootstrap-5.select2-container--open .select2-selection, .was-validated select:invalid + .select2-container--bootstrap-5.select2-container--focus .select2-selection, .was-validated select:invalid + .select2-container--bootstrap-5.select2-container--open .select2-selection {
   border-color:  var(--bs-form-invalid-border-color);
}

/* line 15477 */
.select2-container--bootstrap-5 .select2-selection {
   color: var(--bs-body-color);
   background-color: var(--bs-body-bg);
   border: var(--bs-border-width) solid var(--bs-border-color);
}

/* line 15496 */
.select2-container--bootstrap-5 .select2-dropdown {
   color: var(--bs-body-color);
   background-color: var(--bs-body-bg);
 }