ul {
   &.state-timeline {
      >li {
         &:last-of-type {
            border: none !important;
         }

         >span {
            border: solid thin var(--bs-body-color) !important;
         }

         &:first-of-type {
            >.detail {
               border: solid thin var(--bs-body-color) !important;
               border-radius: 0.25rem;
               padding: 0.25rem 0.75rem 0.5rem;

               >div {
                  margin-top: 0.5rem;
               }

               &::before {
                  content: 'สถานะล่าสุด';
                  font-size: 0.75rem;
                  font-weight: bold;
               }
            }
         }

         >span {
            transform: translate(-50%, -0%) !important;
         }

         &:not(:last-of-type) {
            padding-bottom: 20px;
            border-left: solid thin var(--bs-body-color) !important;
         }
      }
   }
}