$bd_color: #fff;

.avatar {

  &,
  img {
    width: 2rem;
    height: 2rem;

  }

  &+.avatar-content {
    display: inline-block;
    margin-left: 0.75rem;
  }

  $sizes: "sm" 1.5rem,
    "md" 3rem,
    "lg" 4.5rem,
    "xl" 8rem,
    "xxl" 15rem;

  @each $name, $size in $sizes {
    &-#{$name} {

      &,
      img {
        width: $size;
        height: $size;
      }
    }
  }
}

.fss-navbar {
  padding: 0.75rem 0;
  background-color: transparent;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15), inset 0 -1px 0 rgba(255, 255, 255, 0.15);

  &::after {
    position: absolute;
    inset: 0;
    z-index: -1;
    display: block;
    content: "";
    background-image: linear-gradient(rgba(var(--bs-dark-rgb), 1), rgba(var(--bs-dark-rgb), 0.95));
  }

  .navbar-toggler {
    border: 0;

    .bi {
      width: 2rem;
      height: 2rem;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .navbar-brand {
    color: $bd_color;
    transition: transform 0.2s ease-in-out;

    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }

    &:hover img {
      transform: rotate(-5deg) scale(1.1);
    }
  }

  .navbar-toggler,
  .nav-link {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
    color: rgba(255, 255, 255, 0.75);

    &:hover,
    &:focus {
      color: $bd_color;
    }

    &.active {
      font-weight: 600;
      color: $bd_color;
    }
  }

  .navbar-nav-svg {
    display: inline-block;
    vertical-align: -0.125rem;
  }

  .offcanvas-lg {
    background-color: var(--bs-dark-rgb);
    border-left: 0;

    @media (max-width: 991.98px) {
      box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    }
  }

  .dropdown-toggle:focus:not(:focus-visible) {
    outline: 0;
  }

  .dropdown-menu {
    --bs-dropdown-min-width: 12rem;
    --bs-dropdown-padding-x: 0.25rem;
    --bs-dropdown-padding-y: 0.25rem;
    --bs-dropdown-link-hover-bg: rgba(var(--bs-secondary-rgb), 0.1);
    --bs-dropdown-link-active-bg: rgba(var(--bs-secondary-rgb), 1);
    --bs-dropdown-font-size: 0.875rem;
    font-size: 0.875rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
}

.notification-card {
  &.unread {
    background-color: var(--bs-tertiary-bg);
  }

  &:hover {
    background-color: var(--bs-secondary-bg) !important;
  }
}


.notification-dropdown {
  &-toggle {
    &:hover {
      border-color: var(--bs-gray-300);
      background: rgba(0, 0, 0, 0);
    }
  }

  &-menu {
    min-width: 260px;

    @media (min-width: 576px) {
      min-width: 360px;
    }
  }
}

.theme-toggler {
  .bi {
    width: 1em;
    height: 1em;
    vertical-align: -0.125em;
    fill: currentcolor;
  }
}

.scrollToTopBtn {
  position: fixed;
  display: block;
  bottom: 30px;
  right: 30px;
  transform: translateY(100px);
  opacity: 0;
  transition: transform 0.5s, opacity 0.3s;
  z-index: 99;
}

.showScrollBtn {
  transform: translateY(0);
  opacity: 1;
}

.title-style {
  font-weight: 700 !important;
  padding-left: 0.75rem !important;
  margin-bottom: 1.5rem !important;
  border-left: 5px solid var(--bs-info) !important;
}

.fss-layout {
  @media (min-width: 992px) {
    display: grid;
    grid-template-areas: "sidebar main";
    grid-template-columns: 265px auto;
    gap: 0 1.5rem;
  }
}

.fss-sidebar {
  grid-area: sidebar;

  @media (min-width: 992px) {
    position: -webkit-sticky;
    position: sticky;
    top: 5rem;
    display: block !important;
    height: calc(100vh - 6rem);
    overflow-y: auto;
  }

  @media (max-width: 991.98px) {
    .offcanvas-lg {
      border-right-color: var(--bs-border-color);
      box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    }
  }

  .fss-links-group {
    .fss-links-heading {
      .link-arrow {
        transition: all 0.2s ease;

      }

      &[data-bs-toggle="collapse"][aria-expanded="true"] {
        .link-arrow {
          transform: rotate(90deg) !important;
        }
      }

    }

  }

}

.fss-main {
  grid-area: main;

  @media (max-width: 991.98px) {
    max-width: 990px;
    margin-inline: auto;
  }

  @media (min-width: 992px) {
    display: grid;
    grid-template-areas: "intro" "content";
    grid-template-rows: auto 1fr;
    gap: inherit;
  }
}

.fss-intro {
  grid-area: intro;
}

.fss-content {
  grid-area: content;
  min-width: 1px;
}

.fss-footer {
  a {
    color: var(--bs-body-color);
    text-decoration: none;

    &:hover,
    &:focus {
      color: var(--bs-link-hover-color);
      text-decoration: underline;
    }
  }
}

.fss-links-nav {
  @media (max-width: 991.98px) {
    font-size: 0.875rem;
  }

  @media (max-width: 991.98px) {
    -moz-column-count: 2;
    column-count: 1;
    -moz-column-gap: 1.5rem;
    column-gap: 1.5rem;

    .fss-links-group {
      -moz-column-break-inside: avoid;
      break-inside: avoid;
    }
  }
}

%link-pseudo {
  border-radius: var(--bs-border-radius);
  color: var(--bs-emphasis-color);
  background-color: var(--bs-primary-bg-subtle);
}

.fss-links-heading {
  cursor: pointer;
  padding: 0.1875rem 0.5rem;
  margin-top: 0.175rem;

  &:hover,
  &:focus,
  &[aria-expanded="true"] {
    @extend %link-pseudo;
  }
}

.fss-links-link {
  padding: 0.1875rem 0.5rem;
  margin-top: 0.175rem;
  color: var(--bs-body-color);
  text-decoration: none;

  // &.active {
  //   font-weight: 600;
  // }

  &:hover,
  &:focus,
  &.active {
    @extend %link-pseudo;
  }

}

.multi-level {

  .fss-links-link,
  .fss-links-group {
    margin-left: 1.125rem;
  }
}

.fss-gutter {
  --bs-gutter-x: 3rem;
}

.fss-fieldset {
  position: relative !important;
  padding-top: 1.5rem !important;
  margin-top: 2rem !important;
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;

  &,
  &.topic {
    >legend {
      position: absolute !important;
      top: 0 !important;
      transform: translateY(-50%) !important;
      width: auto !important;
      font-weight: 700 !important;
      font-size: 1.25rem !important;
      font-size: 1.25rem !important;
      background-color: var(--bs-body-bg);
      padding-right: 0.75rem !important;

    }
  }

  &.topic {
    >legend {
      border-left: 5px solid var(--bs-info) !important;
      padding-left: 0.75rem !important;
    }
  }
}

table {
  &.table-coop-detail {
    tr th:first-child {
      width: 200px;
    }
  }
}

.dashboard-card {
  &.link-disabled {
    cursor: not-allowed;

    a {
      padding: 2rem;
      pointer-events: none;
      text-decoration: none;

    }
  }

  a::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: "";
  }

  &:not(.link-disabled) a {
    font-weight: 700 !important;
    font-size: calc(1.375rem + 1.25vw) !important;
    --bs-link-underline-opacity: 0;
    text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
  }

}

#scrollHint{
  &.scroll-hint{
    font-size: small;
    text-align: center;
    padding: 8px;
    background-color: var(--bs-secondary-bg-subtle) !important;
    border-bottom: thin solid var(--bs-secondary-border-subtle) !important;
  }
}