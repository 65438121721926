.prograss-step-wizard {
  position: relative;

  .progress {
    height: 2px;
  }

  .point-item {
    position: absolute !important;
    transform: translate(-50%, -50%) !important;
    top: 0 !important;
    width: 2rem;
    height: 2rem;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    background-color: var(--bs-secondary-bg);

    &.active {
      color: #{$white};
      background-color: var(--bs-primary) !important;
    }
  }
}