$enable-shadows: true;
// Include functions first
@import "bootstrap/scss/functions";

//
// Customize some defaults
//
$font-family-base: "Bai Jamjuree", sans-serif;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1860px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  xxxl: 1780px
);

// Required
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

$utilities: map-merge($utilities,
    ("width": map-merge(map-get($utilities, "width"),
        (class:w,
          values: (5: 5%,
            10: 10%,
            15: 15%,
            20: 20%,
            25: 25%,
            30: 30%,
            35: 35%,
            40: 40%,
            45: 45%,
            50: 50%,
            55: 55%,
            60: 60%,
            65: 65%,
            70: 70%,
            75: 75%,
            80: 80%,
            85: 85%,
            90: 90%,
            95: 95%,
            100: 100%,
            auto: auto),
        ),
      ),
    ));

$utilities: map-merge($utilities,
    ("opacity": map-merge(map-get($utilities, "opacity"),
        (values: (0: 0,
            5: .05,
            10: .1,
            25: .25,
            50: .5,
            75: .75,
            100: 1,
          )))));

@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";

@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar"; // Requires nav
@import "bootstrap/scss/card";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal"; // Requires transitions
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas"; // Requires transitions
@import "bootstrap/scss/placeholders";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";

@import "fss-layout";
@import "prograss-step-wizard";
@import "state-timeline";

//
// Custom styles
//
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '~simplebar/src/simplebar.css';
@import '~select2/dist/css/select2.css';
@import '~select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.css';
@import '~vanillajs-datepicker/css/datepicker-bs5.css';
@import '../css/vanillajs-datepicker-bs5.custom.css';
@import '../css/select2.custom.css';
@import '../css/form-validation.custom.css';
@import '../css/bootbox.custom.css';
@import '~@form-validation/plugin-wizard/lib/styles/index.css';