.datepicker-picker {
  background-color: var(--bs-body-bg);
}

.datepicker-dropdown .datepicker-picker {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.datepicker-footer {
  background-color: var(--bs-body-bg);
}

.datepicker-title {
  background-color: #f8f9fa;
}

[data-bs-theme="dark"] .datepicker-controls .btn {
  border-color: var(--bs-gray-700);
  background-color: var(--bs-body-bg);
}

[data-bs-theme="dark"] .datepicker-controls .btn:hover {
  border-color: var(--bs-gray-700);
  background-color: var(--bs-gray-700);
  color: var(--bs-gray-300);
}

.datepicker-controls .btn:focus {
  border-color: #dbe0e5;
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
  background-color: #e2e6ea;
  color: #000;
}

.datepicker-controls .btn:disabled {
  border-color: #f8f9fa;
  background-color: #f8f9fa;
  color: #000;
}

.datepicker-controls .btn:not(:disabled):active {
  border-color: #d4dae0;
  background-color: #dbe0e5;
  color: #000;
}

.datepicker-controls .btn:not(:disabled):active:focus {
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}

.datepicker .week {
  color: #dee2e6;
}

[data-bs-theme="dark"] .datepicker-cell:not(.disabled):hover {
  background-color: #343a40;
}

[data-bs-theme="dark"] .datepicker-cell.focused:not(.selected) {
  background-color: var(--bs-gray-500);
  color: var(--bs-gray-900);
}

.datepicker-cell.selected,
.datepicker-cell.selected:hover {
  background-color: #0d6efd;
  color: #fff;
}

.datepicker-cell.disabled {
  color: rgba(33, 37, 41, 0.5);
}

.datepicker-cell.prev:not(.disabled),
.datepicker-cell.next:not(.disabled) {
  color: #6c757d;
}

.datepicker-cell.prev.selected,
.datepicker-cell.next.selected {
  color: #e6e6e6;
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today) {
  border-radius: 0;
  background-color: #f8f9fa;
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today):not(.disabled):hover {
  background-color: #f1f3f5;
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today).focused {
  background-color: #e2e6ea;
}

[data-bs-theme="dark"] .datepicker-cell.today:not(.selected) {
  background-color: #20c997;
}

.datepicker-cell.today:not(.selected):not(.disabled) {
  color: #fff;
}

.datepicker-cell.today.focused:not(.selected) {
  background-color: #1fc493;
}

.datepicker-cell.range-end:not(.selected),
.datepicker-cell.range-start:not(.selected) {
  background-color: #6c757d;
  color: #fff;
}

.datepicker-cell.range-end.focused:not(.selected),
.datepicker-cell.range-start.focused:not(.selected) {
  background-color: #69727a;
}

[data-bs-theme="dark"] .datepicker-cell.range {
  background-color: var(--bs-gray-700);
}

[data-bs-theme="dark"] .datepicker-cell.range:not(.disabled):not(.focused):not(.today):hover {
  background-color: var(--bs-gray-800);
}

.datepicker-cell.range.disabled {
  color: #ced4db;
}

.datepicker-cell.range.focused {
  background-color: #dbe0e5;
}

.datepicker-input.in-edit {
  border-color: #73acfe;
}

.datepicker-input.in-edit:focus,
.datepicker-input.in-edit:active {
  box-shadow: 0 0 0.25em 0.25em rgba(115, 172, 254, 0.2);
}

.input-group > .datepicker-input:nth-last-child(2) {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}
